import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/3 lg:w-4/12 xl:w-1/4 flex-shrink-0 h-96 md:h-144 bg-contain bg-no-repeat bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc:
        "main.jpg",
      subtitle: "TRACK ALL YOUR STRATEGIES",
      title: "ONE PLACE TO RULE THEM ALL",
      description:
        ["Define buy/sell rules using cryptocoin candle data from the most active exchanges (Binance, Huobi, FTX, Bitfinex and Gate.io for now)"],
      url: "https://timerse.com"
    },

    {
      imageSrc:
        "rules.jpg",
      subtitle: "TRIPLE STAGED RULE SETS",
      title: "IT IS NEVER BEEN SO EASY",
      description:[
        "Rules can be described as native sentences formed with inline switches and options. Volume and amplitude as well as RSI, MA, MACD indicators can also be used to create rules. Select a region in chart and get rule recommendations for pattern shown.",
        "See the previous hits just by one click, no coding required, it is super fast.",        
        "If/then/finally rule sets allow even the most complex algorithms. More than one rule can be combined in each stage. Use the most elaborate filter options with incredible ease"],
      url: "https://timerse.com"
    },

    {
      imageSrc:
        "performance.jpg",
      subtitle: "PERFORMANCE TESTING",
      title: "SEE THE RESULTS",
      description:[
        "Backtesting is the most difficult task even for a algorithm programmer. But you can access this feature in Cryptalgos by default. You can also define stop loss and take profit ratios and find out how your strategy would have performed if you had applied it.", 
        "",
        "Playing with ratios helps you to understand the impact of SL/TP orders on strategy performance"],
      url: "https://timerse.com"
    },
    {
      imageSrc:
        "wallet.jpg",
      subtitle: "SHARE YOUR STRATEGIES",
      title: "HELP OTHERS",
      description:[
        "Show your wisdom, analysis capability and technical excellence by sharing strategies. Create share links and post them to your friends, groups and followers. @CryptalgosBot on Telegram can help you to share links easily in channels and groups."],
      url: "https://timerse.com"
    }

  ];

  return (
    <Container>
      <SingleColumn>
        {/* <HeadingInfoContainer>
          <HeadingTitle>Popular Events</HeadingTitle>
          <HeadingDescription>
            Here are some of the most popular events in New York City curated by professionals.
          </HeadingDescription>
        </HeadingInfoContainer> */}

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc}/>
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                {card.description.map(desc => 
                  (<Description>{desc}</Description>)
                )}
                {/* <Link href={card.url}>See Event Details</Link> */}
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
