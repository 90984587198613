import React,{useRef} from "react";

const stOrders = [
    {"action": "init", "change": 0, "openTime": "2022-02-02T18:15:00Z", "performance": 0, "price": 0, "shares": 0, "total": 0}, 
    {"action": "buy", "change": 0, "openTime": "2022-02-02T21:45:00Z", "performance": 0, "price": 36922.51, "shares": 0.0027083749181732227, "total": 100}, 
    {"action": "sell", "change": 2.720589689054184, "hit": 82, "openTime": "2022-02-04T06:30:00Z", "performance": 2.720589689054184, "price": 37927.02, "shares": 0, "total": 102.72058968905418}, 
    {"action": "buy", "change": 0, "hit": 112, "openTime": "2022-02-04T14:00:00Z", "performance": 2.720589689054184, "price": 37547.36, "shares": 0.002735760641734976, "total": 102.72058968905418}, 
    {"action": "sell", "change": 4.879384329550732, "hit": 118, "openTime": "2022-02-04T15:30:00Z", "performance": 7.732722045564, "price": 39379.44, "shares": 0, "total": 107.732722045564}, 
    {"action": "buy", "change": 0, "hit": 222, "openTime": "2022-02-05T17:15:00Z", "performance": 7.732722045564, "price": 41302.53, "shares": 0.002608380698363127, "total": 107.732722045564}, 
    {"action": "sell", "change": 0.8125652351078735, "hit": 229, "openTime": "2022-02-05T19:00:00Z", "performance": 8.608120691741648, "price": 41638.14, "shares": 0, "total": 108.60812069174165}, 
    {"action": "buy", "change": 0, "hit": 309, "openTime": "2022-02-06T15:00:00Z", "performance": 8.608120691741648, "price": 41359.08, "shares": 0.0026259800917172635, "total": 108.60812069174165}, 
    {"action": "sell", "change": 2.47053367724814, "hit": 344, "openTime": "2022-02-06T23:45:00Z", "performance": 11.291320889657431, "price": 42380.87, "shares": 0, "total": 111.29132088965743}, 
    {"action": "buy", "change": 0, "hit": 348, "openTime": "2022-02-07T00:45:00Z", "performance": 11.291320889657431, "price": 41830.55, "shares": 0.002660527315315181, "total": 111.29132088965743}, 
    {"action": "sell", "change": 1.201131708763093, "hit": 356, "openTime": "2022-02-07T02:45:00Z", "performance": 12.628076233964391, "price": 42332.99, "shares": 0, "total": 112.62807623396439}, 
    {"action": "buy", "change": 0, "hit": 468, "openTime": "2022-02-08T06:45:00Z", "performance": 12.628076233964391, "price": 44791.58, "shares": 0.002514492148612851, "total": 112.62807623396439}, 
    {"action": "stopsell", "change": -4, "hit": 501, "openTime": "2022-02-08T15:00:00Z", "performance": 8.122953184605816, "price": 42999.9168, "shares": 0, "total": 108.12295318460582}, 
    {"action": "buy", "change": 0, "hit": 502, "openTime": "2022-02-08T15:15:00Z", "performance": 8.122953184605816, "price": 43182.99, "shares": 0.0025038320223913584, "total": 108.12295318460582}, 
    {"action": "sell", "change": 0.7095849546314417, "hit": 507, "openTime": "2022-02-08T16:30:00Z", "performance": 8.890177392906976, "price": 43489.41, "shares": 0, "total": 108.89017739290698}, 
    {"action": "buy", "change": 0, "hit": 511, "openTime": "2022-02-08T17:30:00Z", "performance": 8.890177392906976, "price": 42879.05, "shares": 0.002539472712033195, "total": 108.89017739290698}, 
    {"action": "sell", "change": 2.4641870563830124, "hit": 525, "openTime": "2022-02-08T21:00:00Z", "performance": 11.57343504989549, "price": 43935.67, "shares": 0, "total": 111.5734350498955}, 
    {"action": "buy", "change": 0, "hit": 548, "openTime": "2022-02-09T02:45:00Z", "performance": 11.57343504989549, "price": 43340.22, "shares": 0.002574362452472449, "total": 111.5734350498955}, 
    {"action": "sell", "change": 0.5646256525693686, "hit": 560, "openTime": "2022-02-09T05:45:00Z", "performance": 12.203407285640022, "price": 43584.93, "shares": 0, "total": 112.20340728564003}, 
    {"action": "buy", "change": 0, "hit": 572, "openTime": "2022-02-09T08:45:00Z", "performance": 12.203407285640022, "price": 43399.78, "shares": 0.0025853450705427545, "total": 112.20340728564003}, 
    {"action": "sell", "change": 1.7091791709543227, "hit": 588, "labelPos": {"x": 14, "xe": 127.14285714285714, "y": -15}, "openTime": "2022-02-09T12:45:00Z", "path": "M 0,0 l 10,-10 h 113.14285714285714 ", "performance": 14.121164552067228, "price": 44141.56, "shares": 0, "total": 114.12116455206723}, 
    {"action": "buy", "change": 0, "hit": 594, "labelPos": {"x": -82, "xe": -10, "y": 5}, "openTime": "2022-02-09T14:15:00Z", "path": "M 0,0 l -10,10 h -72 ", "performance": 14.121164552067228, "price": 43975.66, "shares": 0.0025950983919756344, "total": 114.12116455206723}, 
    {"action": "sell", "change": 1.2866663058610148, "hit": 615, "labelPos": {"x": -123.14285714285714, "xe": -10, "y": -15}, "openTime": "2022-02-09T19:30:00Z", "path": "M 0,0 l -10,-10 h -113.14285714285714 ", "performance": 15.589523124214882, "price": 44541.48, "shares": 0, "total": 115.58952312421488}
];

export default (props) => {

    const scale = (val) => val*0.75;
    function performanceChangeHeader (intv) {
        return "header"
    }
     
    const Area = (props) => {
        
        var mxY = Math.max(0,...props.data.map(pd => pd.y));
        var mnY = Math.min(0,...props.data.map(pd => pd.y));            
        
        var points = props.data.reduce((result,p,ind) => result+" "+p.x+","+ (props.zeroY - p.y) 
            + (ind == props.data.length - 1 ? " "+(props.length-1)+","+(props.zeroY-props.data[props.data.length-1].y ) : "") 
            , "0,"+props.zeroY)
            +" "+(props.length-1)+","+props.zeroY+" 0,"+props.zeroY;       
        return (
            <g>
                <defs>
                    <linearGradient id="grad" x1="0" y1={0} x2="0" y2={1}>
                        <stop offset={0} stopColor="#009924" stopOpacity="1" />
                        <stop offset={mxY / (mxY - mnY)} stopColor="#c4c4c4" stopOpacity="0.6" />
                        <stop offset={1} stopColor="#e60000" stopOpacity="0.6" />
                    </linearGradient>
                </defs>
                <polygon
                    points = {points}
                    fill="url(#grad)"
                />
                <g>
                    {props.data.map((p) => 
                        (<line key={p.x.toString()} 
                            x1={p.x} 
                            x2={p.x} 
                            y1 = "0"
                            y2 = "100%"
                            strokeWidth= {props.length / 600} 
                            strokeDasharray= {(mxY-mnY) / 30} 
                            stroke = {p.c > 0 ? "#00db1d" : "#ff1f1f"}/>
                        ))
                    }
                </g>                    
            </g>
        )
    }

    function Performance(props) {
        
        const orders = props.orders;
        const height = props.height ?? 80;
        let width = props.width ?? 300; 
        if (width < 150) width = 150;       

        return ( 
        <div style={{display: "flex", height:scale(height), width: scale(width)}} >
            <svg style={{flex: "1 0 85%"}}>
                <g x="0" y="0" width={scale(width)} height={scale(height)}>
                    {[0,1,2,3].map((a) => { 
                        const hgt = scale(height);
                        return <line key = {a.toString()}  
                            x1="0"
                            x2= "100%" 
                            y1 = {hgt/3 * a}
                            y2 = {hgt/3 * a}
                            strokeWidth= "0.6" 
                            strokeDasharray= "2" 
                            stroke = {"grey"}/>
                    })}
                </g>
                <g  x="0" y="0" width={scale(width)} height={scale(height)}>                    
                    <svg width={scale(width)} height={scale(height)}  
                        preserveAspectRatio="none"
                        viewBox={`0 0 ${props.length} ${Math.max(...orders.map(o => o.performance))* 1.2 - Math.min(...orders.map(o => o.performance)) * 1.2}`}>
                            {orders.length > 1 && <Area data={orders.filter(o => o.action.endsWith("sell") && o.hit).map(o => ({x: o.hit, y:o.performance, c: o.change}))} 
                                zeroY = {Math.max(...orders.map(o => o.performance)) *1.2} length={props.length}/>  }                                             
                    </svg>
                </g>
            </svg>
            {orders.length > 1 && <svg>
                <g>{
                    [0,1,2,3].map((a) => { 
                        const hgt = Math.max(...orders.map(o => o.performance))* 1.2 - Math.min(...orders.map(o => o.performance)) * 1.2;                                
                        const minY = Math.min(0,...orders.map(o => o.performance)) *1.2;                                
                        return <text key= {a.toString()}
                                    x = { 5 }
                                    y = {scale(height) * (1 - a/3) + (a == 0 ? 0 : a == 3 ? scale(height) / 10 : 3) }
                                    fontSize = {scale(height / 8)}
                                    fill = "#7a7a7a"
                                    textAnchor="start">            
                                    {((hgt / 3) * a + minY).toFixed(2)+"%"}
                        </text>                             
                    })}                            
                </g>
            </svg>}
        </div>
    )}

    const Header = (props) => {
        let width = props.width ?? 300;
        if (width < 150) width = 150; 
        const wr = width < 500 ? width / 500 : 1;
        const height = props.height * 1.6 * wr ;

        return (
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", 
                paddingInline: scale(5), width: scale(width * 0.85)}}>                
                <div style={{fontSize: scale(9/ 100 * height), fontFamily: "tahoma", 
                    color: "black", marginBottom: scale(5)}}>
                    BTCUSDT, 15m
                </div>
                <div style={{fontSize: scale(7/ 100 * height), fontFamily: "tahoma", 
                    color: "black", marginBottom: scale(5)}}>
                    {"Weekly Performance ending "+new Date().toLocaleDateString("tr")+" "+new Date().toLocaleTimeString("tr")} 
                </div>
            </div>
        )
    }
    const Footer = (props) => {
        let width = props.width ?? 300;
        if (width < 150) width = 150; 
        const wr = width < 500 ? width / 500 : 1;
        const height = props.height * 1.1 * wr ;

        return (
            <div style={{display: "flex", flexDirection:"row", background: "black" , marginTop: scale(2),paddingLeft: scale(5), paddingRight: scale(5),
                     width: scale(width * 0.85), alignItems: 'center', justifyContent: 'space-between'}}>
                <div></div>
                <div style={{display: "flex", flexDirection:"row", alignItems: "center"}}>
                    <div style={{color:"grey", fontSize:scale(13 / 100 * height), marginRight: scale(10 / 100 * height)}}>
                        Generated by
                    </div>
                    <img src="./logo192.png" width={scale(18 / 100 * height)} height={scale(9 / 100 * height)} />
                    <div style={{fontWeight:"bold", color:"white", fontSize:scale(11 / 100 * height)}}>
                        Cryptalgos
                    </div>
                </div>
            </div>
        )
    }

    const width = 700;
    const height = 100;
    return (
        <>
            <Header width={width} height={height}/>
            <Performance length={720} interval={3} orders={stOrders} height={height} width={width} scale={1}/>
            <Footer width={width} height={height}/>
        </>
    );
  };
  