import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/CryptalgosHero";
import Features from "components/features/VerticalWithAlternateImageAndTextCrypt.js";
import DownloadApp from "components/cta/DownloadAppCrypt.js";
import Testimonial from "components/testimonials/TwoColumnWithImage.js";
import ContactUsForm from "components/forms/SimpleContactUs.js";
import Footer from "components/footers/MiniCenteredFooterCrypt.js";

export default () => (
  <AnimationRevealPage>
    <Hero />
    <Features/>
    <DownloadApp/>
    <Footer />
  </AnimationRevealPage>
);
